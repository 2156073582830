import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnderwritingComponent } from './underwriting.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ReviewComponent } from './components/review/review.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewItemValueComponent } from './components/review-item-value/review-item-value.component';
import { SharedModule } from '../shared/shared.module';
import { RecallModule } from '../recall/recall.module';
import { PrintViewComponent } from '../vcall/print-view/print-view.component';
import { VcallModule } from '../vcall/vcall.module';
import { AuthorizationGuard } from '../core/guards/authorization.guard';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionGuard } from '../core/guards/permission.guard';
import { Permission } from '../shared/models/vcall/permission';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { CreateOrderComponent } from './components/create-order/create-order.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { HeaderLogo } from '../shared/models/logo';
import { SafePipeModule } from 'safe-pipe';
// import { GraphqlModule } from '../graphql/graphql.module';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { AddonComponent } from './components/add-on/addon.component';
import { AddonLogsComponent } from './components/addon-logs/addon-logs.component';
import { AddonApplicantComponent } from './components/addon-applicant/addon-applicant.component';
import { TabAccessGuard } from '../core/guards/tab-access.guard';
import { CstPipe } from '../shared/pipes/cst.pipe';
import { BasicDoctorFormComponent } from './components/basic-doctor-form/basic-doctor-form.component';
import { GraphQLModule } from '../graphql/graphql.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

const routes: Routes = [
  {
    path: 'worksheet/application/:applicationId',
    component: UnderwritingComponent,
    pathMatch: 'full',
    canActivate: [AuthorizationGuard, MsalGuard, TabAccessGuard],
    data: {
      logo: HeaderLogo.UW,
    },
  },
  {
    path: 'worksheet/application/:applicationId/:tabName',
    component: UnderwritingComponent,
    pathMatch: 'full',
    canActivate: [AuthorizationGuard, MsalGuard, TabAccessGuard],
    data: {
      logo: HeaderLogo.UW,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NgbNavModule,
    FormsModule,
    SharedModule,
    RecallModule,
    VcallModule,
    ReactiveFormsModule,
    // PdfViewerModule,
    SafePipeModule,
    GraphQLModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    UnderwritingComponent,
    ReviewComponent,
    ReviewItemValueComponent,
    AdditionalInfoComponent,
    CreateOrderComponent,
    OrderDetailsComponent,
    DocumentViewerComponent,
    AddonApplicantComponent,
    AddonComponent,
    AddonLogsComponent,
    BasicDoctorFormComponent,
  ],
  providers: [
    CstPipe,
    provideNgxMask()
  ],
})
export class UnderwritingModule {}