<div class="row m-0">
  <div class="col-2 p-0 pt-3 groups-column">
    <p class="border-bottom text-white text-center pb-2">APPLICANTS</p>
    <nav
      ngbNav
      #nav="ngbNav"
      class="nav flex-column nav-pills sticky-top"
      [(activeId)]="active"
    >
      <ng-container
        *ngFor="let applicant of vcallApplication.applicants; index as i"
      >
        <a
          ngbNavLink
          (click)="linkSelected(applicant)"
          class="text-white text-uppercase"
          [ngbNavItem]="i + 1"
          *ngIf="applicant.applicantType === 'Regular'"
          >{{ applicant.fullName }}
        </a>
      </ng-container>
    </nav>
  </div>
  <div class="col-10 pt-3 bg bg-white">
    <div *ngIf="selectedGroup">
      <div>
        <ng-container>
          <div class="offset-sm-9 col-sm-3 text-right pb-2">
            <label class="form-check-label" for="yesonly">
              <input
                class="form-check-input"
                type="checkbox"
                id="yesonly"
                (change)="toggleYesAndNo($event)"
              />
              Show All</label
            >
          </div>
          <div class="sticky-top">
            <!-- Commenting for now, this feature will be enabled in the future -->

            <!-- <div class="my-2 mx-1 row justify-content-end create-order-bg">
              <button
                type="button"
                class="btn btn-primary btn-sm ml-2 px-4"
                (click)="createRequest()"
                [disabled]="!canCreateOrderUW"
              >
                Create APS/LAB Request
              </button>
            </div> -->
            <div class="card custom-card-bg py-2 px-4 mb-2 mt-0">
              <div class="pb-1 font-weight-bold text-uppercase">
                {{ (selectedApplicant | fullname) || "N/A" }}
                <span class="pl-2"
                  >({{ selectedApplicant.relationship | relation }})</span
                >
              </div>
              <div>
                <div class="row m-0 justify-content-between">
                  <div>
                    <p class="mb-1">
                      <label class="applicant-details">Age :</label>
                      <b class="pl-2">{{ selectedApplicant.age || "N/A" }} </b>
                    </p>
                    <p class="mb-1">
                      <label class="applicant-details">Gender:</label>
                      <b class="pl-2"
                        >{{ selectedApplicant.gender || "N/A" }}
                      </b>
                    </p>
                    <p class="mb-1">
                      <label class="applicant-details">Date of birth:</label>
                      <b class="pl-2"
                        >{{
                          (selectedApplicant.birthDate | date : "MM/dd/yyyy") ||
                            "N/A"
                        }}
                      </b>
                    </p>
                  </div>
                  <div>
                    <p class="mb-1">
                      <label class="applicant-details">Height :</label>
                      <b class="pl-2"
                        >{{ selectedApplicant.height || "N/A" }}
                      </b>
                    </p>
                    <p class="mb-1">
                      <label class="applicant-details"> Weight:</label>
                      <b class="pl-2"
                        >{{ selectedApplicant.weight || "N/A" }}
                      </b>
                    </p>
                    <p class="mb-1">
                      <label class="applicant-details">Place of birth:</label>
                      <b class="pl-2"
                        >{{ selectedApplicant.birthState || "N/A" }},
                        {{ selectedApplicant.birthCountry || "N/A" }}
                      </b>
                    </p>
                  </div>
                  <div>
                    <p class="mb-1">
                      <label class="applicant-details1"
                        >Tobacco use in last 12 months :</label
                      >
                      <b class="pl-2">{{ tobaccoUsageStatus || "N/A" }} </b>
                    </p>
                    <p class="mb-1" *ngIf="rxProfileGlobalGroup?.itemsMaster">
                      <label class="applicant-details1">
                        Rx authorization:</label
                      >
                      <b class="pl-2"
                        >{{
                          (rxProfileGlobalGroup?.itemsMaster)[0]?.vCall
                            .value === "true"
                            ? "Yes"
                            : "No" || "N/A"
                        }}
                      </b>
                    </p>
                    <p class="mb-1">
                      <label class="applicant-details1">SSN:</label>
                      <b class="pl-2"
                        >{{ selectedApplicant.ssn || "N/A" | mask : "4" }}
                      </b>
                    </p>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <p class="mb-1">
                    Employer:
                    <b class="pl-2"
                      >{{ selectedApplicant.employer || "N/A" }}
                    </b>
                  </p>
                </div>
                <div class="row m-0">
                  <p class="mb-1">
                    Occupation:
                    <b class="pl-2">
                      {{
                        getOccupation(
                          occupationGroup?.occupationList,
                          selectedApplicant.clientNo
                        )
                      }}
                    </b>
                  </p>
                </div>

                <div class="row m-0">
                  <p class="mb-1">Health conditions/Hazardous Occupation:</p>
                  <ng-container
                    *ngFor="let item of drillDownGroup?.drillDownitems"
                  >
                    <div
                      class="d-flex"
                      *ngIf="
                        item.conditions &&
                        item.conditions.tag !==
                          'MEDICALHISTORY.GREENCARD.AILMENTS'
                      "
                    >
                      <p
                        class="font-weight-bold d-flex pl-2 mb-0"
                        *ngIf="item.conditions.vCall?.value !== ''"
                      >
                        {{ item.conditions.vCall?.value }} <span>,</span>
                      </p>
                    </div>
                  </ng-container>
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  *ngIf="
                    criticalQuestionDetailsModel &&
                    criticalQuestionDetailsModel.items.length
                  "
                >
                  <h6 class="alert-heading">
                    Critical Conditions (applicant said 'Yes')
                  </h6>
                  <p class="pl-2 text-danger fs-6">
                    <ng-container
                      class="pb-3"
                      *ngFor="let item of criticalQuestionDetailsModel.items"
                    >
                      <ng-container>
                        <ul>
                          <li *ngIf="item.value">
                            {{ item.prompt }}
                          </li>
                        </ul>
                      </ng-container>
                    </ng-container>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="selectedApplicant || !selectedGroup.showApplicantDropdown"
            class="card-container"
          >
            <div>
              <div>
                <h5 class="bg-secondary text-white text-center p-2">
                  Critical Conditions
                </h5>
                <ng-container>
                  <div
                    class="pb-3"
                    *ngFor="let answer of criticalQuestionDetailsModel?.items"
                  >
                    <div class="applicable pt-2">
                      <div class="pt-2" [innerHTML]="answer.prompt"></div>
                      <div
                        [ngClass]="{
                          'text-danger font-weight-bold':
                            answer.value === 'true' || answer.value === true
                        }"
                      >
                        {{
                          answer.value === "true" || answer.value === true
                            ? "Yes"
                            : "No"
                        }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    criticalQuestionDetailsModel &&
                    !criticalQuestionDetailsModel?.items?.length
                  "
                >
                  <p class="call-info m-3">No Data Available</p>
                </ng-container>
              </div>

              <!-- START : Question Notes -->
              <div
                *ngIf="
                  questionNotesGroup?.configuration?.displayLabel ===
                    'VCall Notes';
                  else noQuestionNotesData
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ questionNotesGroup?.configuration?.displayLabel }}
                </h5>
                <ng-container *ngIf="questionNotesGroup?.items.length > 0">
                  <div
                    class="pb-3"
                    *ngFor="let item of questionNotesGroup?.items"
                  >
                    <div class="applicable pt-2">
                      <div [innerHTML]="item.vCall?.prompt"></div>
                      <div
                        class="pt-2 font-weight-bold"
                        [ngClass]="{
                          'agent-notes-bg p-2 mt-2 d-inline-block':
                            item?.vCall.hasOwnProperty('notes')
                        }"
                      >
                        {{ (item?.vCall)["notes"] || "N/A" }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="questionNotesGroup?.items.length === 0">
                  <p class="call-info m-3">No Data Available</p>
                </ng-container>
              </div>
              <ng-template #noQuestionNotesData>
                <ng-container
                  [ngTemplateOutlet]="noDataTemplate"
                  [ngTemplateOutletContext]="{ messageVar: 'VCall Notes' }"
                >
                </ng-container>
              </ng-template>
              <!-- END : Question Notes -->

              <!-- START : ezApp Notes -->
              <div class="py-2">
                <h5 class="bg-secondary text-white text-center p-2">
                  EzApp/Agent Notes
                </h5>
                <ng-container
                  *ngIf="application['notes']?.text; else elseBlock"
                >
                  <div class="pt-2 pb-2">
                    {{ application["notes"]?.text }}
                  </div>
                </ng-container>
                <ng-template #elseBlock>
                  <div class="call-info m-3">No Data Available</div>
                </ng-template>
              </div>
              <!-- END : ezApp Notes -->

              <!-- START : Medical -->
              <div
                *ngIf="
                  medicalGroup?.configuration?.displayLabel === 'Medical';
                  else noMedicalData
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ medicalGroup?.configuration?.displayLabel }}
                </h5>
                <ng-container
                  *ngIf="
                    medicalGroup?.drillDownitems.length > 0 ||
                    generalDetailsGroup.medicalList.length > 0
                  "
                >
                  <div
                    [ngClass]="{ 'border-bottom mb-2': isLast === false }"
                    *ngFor="
                      let item of medicalGroup?.drillDownitems;
                      index as i;
                      last as isLast
                    "
                  >
                    <div class="applicable">
                      <div [innerHTML]="item.applicableItem.vCall.prompt"></div>
                      <div class="d-flex pt-3">
                        <p
                          class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                          [ngClass]="{
                            'bg-green':
                              item.applicableItem.ezApp &&
                              item.applicableItem.ezApp.value === 'Y',
                            'bg-red':
                              item.applicableItem.ezApp &&
                              item.applicableItem.ezApp.value !== 'Y'
                          }"
                        >
                          <span>eZapp</span>
                          <span
                            class="font-weight-bold"
                            [ngClass]="{
                              'text-success':
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value === 'Y',
                              'text-danger':
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value !== 'Y'
                            }"
                          >
                            <ng-container
                              *ngIf="item.applicableItem.ezApp; else elseBlock"
                            >
                              {{
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value === "Y"
                                  ? "Yes"
                                  : "No"
                              }}
                            </ng-container>
                            <ng-template #elseBlock>
                              {{ "N/A" }}
                            </ng-template>
                          </span>
                        </p>
                        <p
                          class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                          [ngClass]="{
                            'bg-green':
                              item.applicableItem.vCall &&
                              item.applicableItem.vCall.value.toString() ===
                                'true',
                            'bg-red':
                              item.applicableItem.vCall &&
                              item.applicableItem.vCall.value.toString() ===
                                'false'
                          }"
                        >
                          <span>VCall</span>
                          <span
                            class="font-weight-bold"
                            [ngClass]="{
                              'text-success':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value.toString() ===
                                  'true',
                              'text-danger':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value.toString() ===
                                  'false'
                            }"
                            >{{
                              item.applicableItem.vCall.value.toString() ===
                              "true"
                                ? "Yes"
                                : "No"
                            }}</span
                          >
                        </p>
                      </div>
                      <ng-container *ngFor="let followup of item.followupItems">
                        <div *ngFor="let question of followup.value">
                          <ng-container
                            *ngIf="
                              question.vCall.value !== null &&
                              question.vCall.value !== '[]'
                            "
                          >
                            <div [innerHTML]="question.vCall?.prompt"></div>
                            <div class="d-flex pt-3">
                              <p
                                class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                              >
                                <span>eZapp</span>
                                <span class="font-weight-bold">
                                  <ng-container
                                    *ngIf="question.ezApp; else elseBlock"
                                  >
                                    {{
                                      question.ezApp &&
                                      question.ezApp?.value === "Y"
                                        ? "Yes"
                                        : "No"
                                    }}
                                  </ng-container>
                                  <ng-template #elseBlock>
                                    {{ "N/A" }}
                                  </ng-template>
                                </span>
                              </p>
                              <p
                                class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                              >
                                <span>VCall</span>
                                <span
                                  class="font-weight-bold"
                                  *ngIf="isValueIsBoolean(question.tag)"
                                  >{{
                                    question.vCall.value.toString() === "true"
                                      ? "Yes"
                                      : "No"
                                  }}</span
                                >
                                <span *ngIf="!isValueIsBoolean(question.tag)">{{
                                  question.vCall.value || "N/A"
                                }}</span>
                              </p>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                    <ng-template [ngIf]="item.conditions">
                      <div class="card-body conditions border-bottom py-1">
                        <div [innerHTML]="item.conditions.vCall?.prompt"></div>
                        <div class="row pt-2">
                          <p
                            class="border d-flex justify-content-around col-sm-6 p-2"
                          >
                            <span>eZapp</span>
                            <span class="d-block">
                              <ng-container
                                *ngIf="
                                  item.conditions?.ezApp !== undefined &&
                                    isValueAnArray(
                                      item.conditions?.ezApp?.value
                                    );
                                  else notAvoilable
                                "
                              >
                                <span
                                  class="font-weight-bold"
                                  *ngFor="
                                    let val of item.conditions.ezApp?.value;
                                    index as i;
                                    last as isLast
                                  "
                                >
                                  {{ val.Condition }}
                                  <span *ngIf="isLast === false">,</span>
                                </span>
                              </ng-container>
                              <ng-template #notAvoilable>
                                <span class="font-weight-bold">{{
                                  "N/A"
                                }}</span>
                              </ng-template>
                            </span>
                          </p>
                          <p
                            class="border d-flex justify-content-around col-sm-6 p-2 border-left-none"
                          >
                            <span>VCall</span>
                            <span class="font-weight-bold">{{
                              item.conditions.vCall?.value || "N/A"
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <!-- General questions without having clientNo starts from here -->
                  <ng-container *ngIf="selectedApplicant.relationship === 'P'">
                    <div *ngFor="let item of generalDetailsGroup.medicalList">
                      <div [innerHTML]="item.vCall?.prompt"></div>
                      <div class="d-flex pt-3">
                        <p
                          class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                        >
                          <span>eZapp</span>
                          <span class="font-weight-bold">
                            <ng-container *ngIf="item.ezApp; else elseBlock">
                              {{
                                item.ezApp && item.ezApp.value === "Y"
                                  ? "Yes"
                                  : "No"
                              }}
                            </ng-container>
                            <ng-template #elseBlock>
                              {{ "N/A" }}
                            </ng-template>
                          </span>
                        </p>
                        <p
                          class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                        >
                          <span>VCall</span>
                          <span
                            class="font-weight-bold"
                            *ngIf="
                              item.vCall?.value === 'true' ||
                                item.vCall?.value === 'false';
                              else viewNotes
                            "
                            >{{
                              item.vCall.value?.toString() === "true"
                                ? "Yes"
                                : "No"
                            }}</span
                          >
                          <ng-template #viewNotes>
                            <span
                              class="font-weight-bold"
                              [ngClass]="{
                                'text-danger': highlightInRed(item.tag)
                              }"
                              >{{ item.vCall.value || "N/A" }}
                            </span>
                          </ng-template>
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <!-- General questions without having clientNo ends here -->
                </ng-container>
                <ng-container
                  *ngIf="
                    medicalGroup?.drillDownitems.length === 0 &&
                    generalDetailsGroup?.medicalList.length > 0 &&
                    selectedApplicant?.relationship !== 'P'
                  "
                >
                  <div class="m-3 call-info">No Data Available</div>
                </ng-container>
              </div>
              <ng-template #noMedicalData>
                <ng-container
                  [ngTemplateOutlet]="noDataTemplate"
                  [ngTemplateOutletContext]="{ messageVar: 'Medical' }"
                >
                </ng-container>
              </ng-template>
              <!-- END : Medical -->

              <!-- START : MedicalHistoryApplicability -->
              <div
                *ngIf="
                  drillDownGroup?.configuration?.displayLabel ===
                    'Medical History';
                  else noMedicalHistoryData
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ drillDownGroup?.configuration?.displayLabel }}
                </h5>
                <ng-container
                  *ngIf="
                    drillDownGroup?.drillDownitems.length > 0 ||
                    generalDetailsGroup.medicalHistoryList.length > 0
                  "
                >
                  <div *ngFor="let item of drillDownGroup?.drillDownitems">
                    <div
                      class="applicable"
                      *ngIf="
                        item.applicableItem.tag !==
                          'MEDICALHISTORY.PRESCRIPTIONDRUGS.PRESCRIPTIONDRUGS' &&
                        item.applicableItem.tag !==
                          'MEDICALHISTORY.ADDRESSOFDOCTOR.ADDRESSOFDOCTOR'
                      "
                    >
                      <div [innerHTML]="item.applicableItem.vCall.prompt"></div>
                      <div class="d-flex pt-3">
                        <p
                          class="border col-sm-6 p-2 d-flex justify-content-around"
                          [ngClass]="{
                            'bg-green':
                              item.applicableItem.ezApp &&
                              item.applicableItem.ezApp.value === 'Y',
                            'bg-red':
                              item.applicableItem.ezApp &&
                              item.applicableItem.ezApp.value !== 'Y'
                          }"
                        >
                          <span>eZapp</span>
                          <span
                            class="font-weight-bold"
                            [ngClass]="{
                              'text-success':
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value === 'Y',
                              'text-danger':
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value !== 'Y'
                            }"
                          >
                            <ng-container
                              *ngIf="item.applicableItem.ezApp; else elseBlock"
                            >
                              {{
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value === "Y"
                                  ? "Yes"
                                  : "No"
                              }}
                            </ng-container>
                            <ng-template #elseBlock>
                              {{ "N/A" }}
                            </ng-template>
                          </span>
                        </p>
                        <!-- For Us citizen question flipping the answer in vcall -->
                        <ng-container
                          *ngIf="
                            item.applicableItem.tag ===
                              'MEDICALHISTORY.GREENCARD.APPLICABLE';
                            else notUsCitizen
                          "
                        >
                          <p
                            class="border col-sm-6 p-2 border-left-none d-flex justify-content-around"
                            [ngClass]="{
                              'bg-green':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value === false,
                              'bg-red':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value === true
                            }"
                          >
                            <span>VCall</span>
                            <span
                              class="font-weight-bold"
                              [ngClass]="{
                                'text-success':
                                  item.applicableItem.vCall &&
                                  item.applicableItem.vCall.value === false,
                                'text-danger':
                                  item.applicableItem.vCall &&
                                  item.applicableItem.vCall.value === true
                              }"
                              >{{
                                item.applicableItem.vCall.value ? "No" : "Yes"
                              }}</span
                            >
                          </p>
                        </ng-container>
                        <ng-template #notUsCitizen>
                          <p
                            class="border col-sm-6 p-2 border-left-none d-flex justify-content-around"
                            [ngClass]="{
                              'bg-green':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value === true,
                              'bg-red':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value === false
                            }"
                          >
                            <span>VCall</span>
                            <span
                              class="font-weight-bold"
                              [ngClass]="{
                                'text-success':
                                  item.applicableItem.vCall &&
                                  item.applicableItem.vCall.value === true,
                                'text-danger':
                                  item.applicableItem.vCall &&
                                  item.applicableItem.vCall.value === false
                              }"
                              >{{
                                item.applicableItem.vCall.value ? "Yes" : "No"
                              }}</span
                            >
                          </p>
                        </ng-template>
                      </div>
                      <ng-container *ngFor="let followup of item.followupItems">
                        <div *ngFor="let question of followup.value">
                          <ng-container
                            *ngIf="
                              question.vCall.value !== null &&
                              question.vCall.value !== '[]' &&
                              question.tag !==
                                'MEDICALHISTORY.PRESCRIPTIONDRUGS.PRESCRIPTIONDRUGS' &&
                              question.tag !==
                                'MEDICALHISTORY.ADDRESSOFDOCTOR.ADDRESSOFDOCTOR'
                            "
                          >
                            <div [innerHTML]="question.vCall?.prompt"></div>
                            <div class="d-flex pt-3">
                              <p
                                class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                              >
                                <span>eZapp</span>
                                <span class="font-weight-bold">
                                  <ng-container
                                    *ngIf="question.ezApp; else elseBlock"
                                  >
                                    {{
                                      question.ezApp &&
                                      question.ezApp.value === "Y"
                                        ? "Yes"
                                        : "No"
                                    }}
                                  </ng-container>
                                  <ng-template #elseBlock>
                                    {{ "N/A" }}
                                  </ng-template>
                                </span>
                              </p>
                              <p
                                class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                              >
                                <span>VCall</span>
                                <span
                                  class="font-weight-bold"
                                  *ngIf="isValueIsBoolean(question.tag)"
                                  >{{
                                    question.vCall.value?.toString() === "true"
                                      ? "Yes"
                                      : "No"
                                  }}</span
                                >
                                <span *ngIf="!isValueIsBoolean(question.tag)">{{
                                  question.vCall.value || "N/A"
                                }}</span>
                              </p>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="item.conditions">
                      <div class="card-body conditions border-bottom py-1">
                        <div [innerHTML]="item.conditions.vCall?.prompt"></div>
                        <div class="row pt-2">
                          <div
                            class="border d-flex flex-wrap justify-content-around col-sm-6 p-2"
                          >
                            <div>eZapp</div>
                            <div class="d-block">
                              <ng-container
                                *ngIf="
                                  item.conditions?.ezApp !== undefined &&
                                    isValueAnArray(
                                      item.conditions?.ezApp?.value
                                    );
                                  else notAvoilable
                                "
                              >
                                <span
                                  class="font-weight-bold"
                                  *ngFor="
                                    let val of item.conditions.ezApp?.value;
                                    index as i;
                                    last as isLast
                                  "
                                >
                                  {{ val.Condition }}
                                  <span *ngIf="isLast === false">,</span>
                                </span>
                              </ng-container>
                              <ng-template #notAvoilable>
                                <span class="font-weight-bold">{{
                                  "N/A"
                                }}</span>
                              </ng-template>
                            </div>
                          </div>
                          <div
                            class="border d-flex justify-content-around col-sm-6 p-2 border-left-none"
                          >
                            <span>VCall</span>
                            <span class="font-weight-bold">{{
                              item.conditions.vCall?.value || "N/A"
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="border mt-3">
                        <p class="px-3 m-0 py-2">
                          Health Information Recieved Originally from ezApp
                        </p>
                        <div class="d-flex p-2">
                          <ng-container
                            *ngIf="
                              item.conditions?.tag ===
                                'MEDICALHISTORY.PRESCRIPTIONDRUGS.AILMENTS';
                              else notPrescLabel
                            "
                          >
                            <div>
                              <p class="col">DrugName</p>
                              <p class="col">Reason</p>
                              <p class="col">Doctor/Hospital Name</p>
                            </div>
                          </ng-container>
                          <ng-template #notPrescLabel>
                            <div>
                              <p class="col">Condition</p>
                              <p class="col">Date</p>
                              <p class="col">Doctor/Hospital Name</p>
                            </div>
                          </ng-template>
                          <ng-container
                            *ngIf="
                              item.conditions?.ezApp !== undefined &&
                                (isValueAnArray(
                                  item.conditions?.ezApp?.value
                                ) ||
                                  item.conditions?.tag ===
                                    'MEDICALHISTORY.PRESCRIPTIONDRUGS.AILMENTS');
                              else elseBlock
                            "
                          >
                            <div
                              *ngFor="
                                let ezAppVal of item.conditions?.ezApp?.value
                              "
                            >
                              <ng-container
                                *ngIf="
                                  item.conditions?.tag ===
                                    'MEDICALHISTORY.PRESCRIPTIONDRUGS.AILMENTS';
                                  else notPrescVal
                                "
                              >
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.DrugName }}
                                </p>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.Reason }}
                                </p>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.Doctor }}
                                </p>
                              </ng-container>
                              <ng-template #notPrescVal>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.Condition }}
                                </p>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.Date }}
                                </p>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.DocName }}
                                </p>
                              </ng-template>
                            </div>
                          </ng-container>
                          <ng-template #elseBlock>
                            <div>
                              <p class="col font-weight-bold">
                                {{ item.conditions?.ezApp?.value || "N/A" }}
                              </p>
                              <p class="col font-weight-bold">{{ "N/A" }}</p>
                              <p class="col font-weight-bold">{{ "N/A" }}</p>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngFor="let condition of item.items">
                      <p
                        class="font-weight-bold mt-2 mb-2"
                        *ngIf="condition?.value?.length > 0"
                      >
                        <span class="body-header-text">{{
                          condition.key || "N/A"
                        }}</span>
                        (VCall - Drill Down)
                      </p>
                      <table class="table table-condensed border">
                        <tr *ngFor="let detail of condition.value">
                          <td
                            class="pl-3 table-drilldown-left"
                            [innerHTML]="detail.vCall.prompt"
                          ></td>
                          <td class="answer table-drilldown-right">
                            <app-review-item-value
                              [value]="detail.vCall.value"
                            ></app-review-item-value>
                          </td>
                        </tr>
                      </table>
                    </ng-container>
                    <!-- doctor and prescription qualifying question starts from here -->
                    <div
                      *ngIf="
                        (item.applicableItem.tag ===
                          'MEDICALHISTORY.PRESCRIPTIONDRUGS.PRESCRIPTIONDRUGS' ||
                          item.applicableItem.tag ===
                            'MEDICALHISTORY.ADDRESSOFDOCTOR.ADDRESSOFDOCTOR') &&
                        item.applicableItem.vCall.value !== '[]'
                      "
                    >
                      <div
                        class="pt-2 pb-2"
                        [innerHTML]="item.applicableItem.vCall.prompt"
                      ></div>
                      <table class="table table-condensed border">
                        <tr>
                          <td class="pl-3 table-drilldown-left">
                            <ng-container
                              *ngFor="
                                let detail of item.applicableItem.ezApp?.value
                              "
                            >
                              <div
                                class="pt-2"
                                *ngFor="let key of objectKeys(detail)"
                              >
                                <ng-container
                                  *ngIf="key !== 'AppId' && key !== 'BRCode'"
                                >
                                  {{ key }}:
                                  <span class="pl-2">{{
                                    detail[key] || "N/A"
                                  }}</span>
                                </ng-container>
                              </div>
                            </ng-container>
                          </td>
                          <td class="answer table-drilldown-right">
                            <app-review-item-value
                              [value]="item.applicableItem.vCall.value"
                            ></app-review-item-value>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- doctor and prescription qualifying question ends here -->
                  </div>
                  <!-- General questions without having clientNo starts from here -->
                  <ng-container *ngIf="selectedApplicant.relationship === 'P'">
                    <div
                      *ngFor="
                        let item of generalDetailsGroup.medicalHistoryList
                      "
                    >
                      <div [innerHTML]="item.vCall?.prompt"></div>
                      <div class="d-flex pt-3">
                        <p
                          class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                        >
                          <span>eZapp</span>
                          <span class="font-weight-bold">
                            <ng-container *ngIf="item.ezApp; else elseBlock">
                              {{
                                item.ezApp && item.ezApp.value === "Y"
                                  ? "Yes"
                                  : "No"
                              }}
                            </ng-container>
                            <ng-template #elseBlock>
                              {{ "N/A" }}
                            </ng-template>
                          </span>
                        </p>
                        <p
                          class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                        >
                          <span>VCall</span>
                          <span
                            class="font-weight-bold"
                            *ngIf="
                              item.vCall?.value === 'true' ||
                                item.vCall?.value === 'false';
                              else viewNotes
                            "
                            >{{
                              item.vCall.value?.toString() === "true"
                                ? "Yes"
                                : "No"
                            }}</span
                          >
                          <ng-template #viewNotes>
                            {{ item.vCall.value || "N/A" }}
                          </ng-template>
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <!-- General questions without having clientNo ends here -->
                </ng-container>
                <ng-container
                  *ngIf="
                    (drillDownGroup?.drillDownitems.length === 0 &&
                      generalDetailsGroup.medicalHistoryList.length === 0) ||
                    (generalDetailsGroup.medicalHistoryList.length === 0 &&
                      generalDetailsGroup.medicalHistoryList.length > 0 &&
                      selectedApplicant.relationship !== 'P')
                  "
                >
                  <div class="mt-2 mb-2 text-center call-info">
                    No Data Available
                  </div>
                </ng-container>
              </div>
              <ng-template #noMedicalHistoryData>
                <ng-container
                  [ngTemplateOutlet]="noDataTemplate"
                  [ngTemplateOutletContext]="{ messageVar: 'Medical History' }"
                >
                </ng-container>
              </ng-template>
              <!-- END : MedicalHistoryApplicability -->

              <!-- START : Demographic -->
              <div
                *ngIf="
                  demoGraphicGroup?.configuration?.displayLabel ===
                    'Demographic';
                  else noDemographic
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ demoGraphicGroup?.configuration?.displayLabel }}
                </h5>
                <ng-container
                  *ngIf="
                    demoGraphicGroup?.drillDownitems ||
                    generalDetailsGroup.demographicList.length > 0
                  "
                >
                  <ng-container
                    *ngIf="demoGraphicGroup?.applicantContactList?.length > 0"
                  >
                    <div class="pt-2 d-flex">
                      <h6 class="body-header-text col-sm-4 p-0">
                        Address Details
                      </h6>
                      <p class="col-sm-4 p-0">eZapp</p>
                      <p class="col-sm-4 p-0">VCall</p>
                    </div>
                    <table class="table table-striped table-sm table-bordered">
                      <tbody
                        *ngFor="let item of demoGraphicGroup?.drillDownMaster"
                      >
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.STREETADDRESS'
                          "
                        >
                          <th class="payment-col-width">Street Address</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.streetAddress ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.CITY'
                          "
                        >
                          <th class="payment-col-width">City</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.city ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.STATE'
                          "
                        >
                          <th class="payment-col-width">State</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.state ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.ZIPCODE'
                          "
                        >
                          <th class="payment-col-width">Zipcode</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.zipCode ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.EMAIL'
                          "
                        >
                          <th class="payment-col-width">Email</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.email ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.HOMEPHONE'
                          "
                        >
                          <th class="payment-col-width">Home phone</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.homePhone ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.MOBILEPHONE'
                          "
                        >
                          <th class="payment-col-width">Mobile phone</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.mobilePhone ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item?.applicableItem.tag ===
                            'APPLICATION.CONTACT.WORKPHONE'
                          "
                        >
                          <th class="payment-col-width">Work phone</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.applicableItem.ezApp?.value ||
                                application.contact.workPhone ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span
                              [ngClass]="{
                                'match-details px-2':
                                  item?.applicableItem.vCall?.value !==
                                  item?.applicableItem.ezApp?.value
                              }"
                            >
                              {{ item?.applicableItem.vCall?.value || "N/A" }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <ng-container>
                    <div class="pt-2 d-flex">
                      <h6 class="body-header-text col-sm-4 p-0">
                        Personal Details
                      </h6>
                      <p class="col-sm-4 p-0">eZapp</p>
                      <p class="col-sm-4 p-0">VCall</p>
                    </div>
                    <table class="table table-striped table-sm table-bordered">
                      <tbody
                        *ngFor="
                          let item of demoGraphicGroup?.personalDetailsItems
                        "
                      >
                        <tr>
                          <th class="payment-col-width">
                            {{ item.displayLabel }}
                          </th>
                          <td class="payment-col-width bg-white">
                            {{ item?.ezAppValue || "N/A" }}
                          </td>
                          <td class="payment-col-width bg-white">
                            <span>
                              {{ item?.vCallValue || "N/A" }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <!-- employment details starts here -->
                  <ng-container
                    *ngIf="
                      occupationGroup?.occupationList?.length > 0 &&
                        getType(
                          selectedApplicant.clientNo,
                          occupationGroup?.occupationList
                        );
                      else employmentNone
                    "
                  >
                    <div class="pt-2 d-flex">
                      <h6 class="body-header-text col-sm-4 p-0">
                        Employment Details
                      </h6>
                      <p class="col-sm-4 p-0">eZapp</p>
                      <p class="col-sm-4 p-0">VCall</p>
                    </div>
                    <!-- Employed details start -->
                    <div *ngIf="employedList.length > 0">
                      <ng-container
                        [ngTemplateOutlet]="employeeDetailsTemplate"
                        [ngTemplateOutletContext]="{
                          $implicit: { data: employedList, header: 'Employed' }
                        }"
                      >
                      </ng-container>
                    </div>
                    <!-- Employed details end -->

                    <!-- Unemployed details start -->
                    <div *ngIf="unEmployedList.length > 0">
                      <ng-container
                        [ngTemplateOutlet]="employeeDetailsTemplate"
                        [ngTemplateOutletContext]="{
                          $implicit: {
                            data: unEmployedList,
                            header: 'Unemployed'
                          }
                        }"
                      >
                      </ng-container>
                    </div>
                    <!-- Unemployed details end -->

                    <!-- FullTime Student details start -->
                    <div *ngIf="fullTimeStudentList.length > 0">
                      <ng-container
                        [ngTemplateOutlet]="employeeDetailsTemplate"
                        [ngTemplateOutletContext]="{
                          $implicit: {
                            data: fullTimeStudentList,
                            header: 'FullTime Student'
                          }
                        }"
                      >
                      </ng-container>
                    </div>
                    <!-- FullTime Student details end -->
                  </ng-container>
                  <!-- if select none option in employment -->
                  <ng-template #employmentNone>
                    <p>Employement <b>None</b></p>
                  </ng-template>
                  <!-- Employment details ends here -->
                  <ng-container
                    *ngIf="demoGraphicGroup?.drillDownitems.length > 0"
                  >
                    <div
                      [ngClass]="{ 'border-bottom mb-2': isLast === false }"
                      *ngFor="
                        let item of demoGraphicGroup?.drillDownitems;
                        index as i;
                        last as isLast
                      "
                    >
                      <div class="applicable">
                        <div
                          [innerHTML]="item.applicableItem.vCall.prompt"
                        ></div>
                        <div class="d-flex pt-3">
                          <p
                            class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                            [ngClass]="{
                              'bg-green':
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value === 'Y',
                              'bg-red':
                                item.applicableItem.ezApp &&
                                item.applicableItem.ezApp.value !== 'Y'
                            }"
                          >
                            <span>eZapp</span>
                            <span
                              class="font-weight-bold"
                              [ngClass]="{
                                'text-success':
                                  item.applicableItem.ezApp &&
                                  item.applicableItem.ezApp.value === 'Y',
                                'text-danger':
                                  item.applicableItem.ezApp &&
                                  item.applicableItem.ezApp.value !== 'Y'
                              }"
                            >
                              <ng-container
                                *ngIf="
                                  item.applicableItem.ezApp;
                                  else elseBlock
                                "
                              >
                                {{
                                  item.applicableItem.ezApp &&
                                  item.applicableItem.ezApp.value === "Y"
                                    ? "Yes"
                                    : "No"
                                }}
                              </ng-container>
                              <ng-template #elseBlock>
                                {{ "N/A" }}
                              </ng-template>
                            </span>
                          </p>
                          <p
                            class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                            [ngClass]="{
                              'bg-green':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value.toString() ===
                                  'true',
                              'bg-red':
                                item.applicableItem.vCall &&
                                item.applicableItem.vCall.value.toString() ===
                                  'false'
                            }"
                          >
                            <span>VCall</span>
                            <span
                              class="font-weight-bold"
                              [ngClass]="{
                                'text-success':
                                  item.applicableItem.vCall &&
                                  item.applicableItem.vCall.value.toString() ===
                                    'true',
                                'text-danger':
                                  item.applicableItem.vCall &&
                                  item.applicableItem.vCall.value.toString() ===
                                    'false'
                              }"
                              >{{
                                item.applicableItem.vCall.value.toString() ===
                                "true"
                                  ? "Yes"
                                  : "No"
                              }}</span
                            >
                          </p>
                        </div>
                        <!-- followup questions starts from here -->
                        <ng-container
                          *ngFor="let followup of item.followupItems"
                        >
                          <div *ngFor="let question of followup.value">
                            <ng-container
                              *ngIf="
                                question.vCall.value !== null &&
                                question.vCall.value !== '[]'
                              "
                            >
                              <div [innerHTML]="question.vCall?.prompt"></div>
                              <div class="d-flex pt-3">
                                <p
                                  class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                                >
                                  <span>eZapp</span>
                                  <span class="font-weight-bold">
                                    <ng-container
                                      *ngIf="question.ezApp; else elseBlock"
                                    >
                                      {{
                                        question.ezApp &&
                                        question.ezApp.value === "Y"
                                          ? "Yes"
                                          : "No"
                                      }}
                                    </ng-container>
                                    <ng-template #elseBlock>
                                      {{ "N/A" }}
                                    </ng-template>
                                  </span>
                                </p>
                                <p
                                  class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                                >
                                  <span>VCall</span>
                                  <span
                                    class="font-weight-bold"
                                    *ngIf="isValueIsBoolean(question.tag)"
                                    >{{
                                      question.vCall.value.toString() === "true"
                                        ? "Yes"
                                        : "No"
                                    }}</span
                                  >
                                  <span
                                    *ngIf="!isValueIsBoolean(question.tag)"
                                    >{{ question.vCall.value || "N/A" }}</span
                                  >
                                </p>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                        <!-- followup questions ends here -->
                      </div>
                      <div *ngIf="item.conditions">
                        <div class="card-body conditions border-bottom py-1">
                          <div
                            [innerHTML]="item.conditions.vCall?.prompt"
                          ></div>
                          <div class="row pt-2">
                            <div
                              class="border d-flex flex-wrap justify-content-around col-sm-6 p-2"
                            >
                              <div>eZapp</div>
                              <div class="d-block">
                                <ng-container
                                  *ngIf="
                                    item.conditions?.ezApp !== undefined &&
                                      isValueAnArray(
                                        item.conditions?.ezApp?.value
                                      );
                                    else notAvoilable
                                  "
                                >
                                  <span
                                    class="font-weight-bold"
                                    *ngFor="
                                      let val of item.conditions.ezApp?.value;
                                      index as i;
                                      last as isLast
                                    "
                                  >
                                    {{ val.Condition }}
                                    <span *ngIf="isLast === false">,</span>
                                  </span>
                                </ng-container>
                                <ng-template #notAvoilable>
                                  <span class="font-weight-bold">{{
                                    item.conditions?.ezApp?.value || "N/A"
                                  }}</span>
                                </ng-template>
                              </div>
                            </div>
                            <div
                              class="border d-flex justify-content-around col-sm-6 p-2 border-left-none"
                            >
                              <span>VCall</span>
                              <span class="font-weight-bold">{{
                                item.conditions.vCall?.value || "N/A"
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="border mt-3"
                          *ngIf="
                            item.conditions.tag !==
                            'DEMOGRAPHIC.HEALTHINSURANCE.EXISTINGCOVERAGE.NOTES'
                          "
                        >
                          <p class="px-3 m-0 py-2">
                            Health Information Recieved Originally from ezApp
                          </p>
                          <div class="d-flex p-2">
                            <div>
                              <p class="col">Condition</p>
                              <p class="col">Date</p>
                              <p class="col">Doctor/Hospital Name</p>
                            </div>
                            <ng-container
                              *ngIf="
                                item.conditions?.ezApp !== undefined &&
                                  isValueAnArray(item.conditions?.ezApp?.value);
                                else elseBlock
                              "
                            >
                              <div
                                *ngFor="
                                  let ezAppVal of item.conditions?.ezApp?.value
                                "
                              >
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.Condition }}
                                </p>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.Date }}
                                </p>
                                <p class="col font-weight-bold">
                                  {{ ezAppVal.DocName }}
                                </p>
                              </div>
                            </ng-container>
                            <ng-template #elseBlock>
                              <div>
                                <p class="col font-weight-bold">
                                  {{ item.conditions?.ezApp?.value || "N/A" }}
                                </p>
                                <p class="col font-weight-bold">{{ "N/A" }}</p>
                                <p class="col font-weight-bold">{{ "N/A" }}</p>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngFor="let condition of item.items">
                        <p
                          class="font-weight-bold mt-2 mb-2"
                          *ngIf="condition?.value?.length > 0"
                        >
                          <span class="body-header-text">{{
                            condition.key || "N/A"
                          }}</span>
                          (VCall - Drill Down)
                        </p>
                        <table class="table table-condensed border">
                          <tr *ngFor="let detail of condition.value">
                            <td
                              class="pl-3 table-drilldown-left"
                              [innerHTML]="detail.vCall.prompt"
                            ></td>
                            <td class="answer table-drilldown-right">
                              <app-review-item-value
                                [value]="detail.vCall.value"
                              ></app-review-item-value>
                            </td>
                          </tr>
                        </table>
                      </ng-container>
                    </div>
                  </ng-container>
                  <!-- General questions without having clientNo starts from here -->
                  <ng-container *ngIf="selectedApplicant.relationship === 'P'">
                    <div
                      *ngFor="let item of generalDetailsGroup.demographicList"
                    >
                      <div [innerHTML]="item.vCall?.prompt"></div>
                      <div class="d-flex pt-3">
                        <p
                          class="border col-sm-6 p-2 d-flex d-flex justify-content-around"
                        >
                          <span>eZapp</span>
                          <span class="font-weight-bold">
                            <ng-container *ngIf="item.ezApp; else elseBlock">
                              {{
                                item.ezApp && item.ezApp.value === "Y"
                                  ? "Yes"
                                  : "No"
                              }}
                            </ng-container>
                            <ng-template #elseBlock>
                              {{ "N/A" }}
                            </ng-template>
                          </span>
                        </p>
                        <p
                          class="border col-sm-6 p-2 border-left-none d-flex d-flex justify-content-around"
                        >
                          <span>VCall</span>
                          <span
                            class="font-weight-bold"
                            *ngIf="
                              item.vCall?.value === 'true' ||
                                item.vCall?.value === 'false';
                              else viewNotes
                            "
                            >{{
                              item.vCall.value?.toString() === "true"
                                ? "Yes"
                                : "No"
                            }}</span
                          >
                          <ng-template #viewNotes>
                            {{ item.vCall.value || "N/A" }}
                          </ng-template>
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <!-- General questions without having clientNo ends here -->
                </ng-container>
              </div>
              <ng-template #noDemographic>
                <ng-container
                  [ngTemplateOutlet]="noDataTemplate"
                  [ngTemplateOutletContext]="{ messageVar: 'Demographic' }"
                >
                </ng-container>
              </ng-template>
              <!-- END : Demographic -->

              <!-- START : Payment section starts -->
              <div
                *ngIf="
                  paymentGroup?.configuration?.displayLabel === 'Payment';
                  else noPaymentData
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ paymentGroup.configuration.displayLabel }}
                </h5>
                <ng-container *ngIf="paymentGroup?.itemsMaster.length > 0">
                  <ng-container
                    *ngIf="paymentGroup.InitialPaymentList.length > 0"
                  >
                    <div class="pt-2 d-flex">
                      <h6 class="payment-col-width body-header-text">
                        Initial Payment Details
                      </h6>
                      <p class="payment-col-width">eZapp</p>
                      <p class="payment-col-width">VCall</p>
                    </div>
                    <table class="table table-striped table-sm table-bordered">
                      <tbody *ngFor="let item of paymentGroup?.itemsMaster">
                        <tr
                          *ngIf="
                            item.tag === 'APPLICATION.PAYMENT.INITIALPAYMENTBY'
                          "
                        >
                          <th class="payment-col-width">Initial payment by</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.initialPaymentBy ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ item?.vCall?.value || "N/A" }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item.tag === 'APPLICATION.PAYMENT.INITPMTBANKNAME'
                          "
                        >
                          <th class="payment-col-width">Bank Name</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.initPmtBankName ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ item?.vCall?.value || "N/A" }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item.tag ===
                            'APPLICATION.PAYMENT.INITPMTBANKROUTINGCODE'
                          "
                        >
                          <th class="payment-col-width">Routing</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.initPmtBankRoutingCode ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ item?.vCall?.value || "N/A" }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item.tag ===
                            'APPLICATION.PAYMENT.INITPMTBDACCOUNTNUMBER'
                          "
                        >
                          <th class="payment-col-width">Account</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.initPmtBDAccountNumber ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ (item?.vCall?.value | maskAccountNumber) || "N/A" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <ng-container
                    *ngIf="paymentGroup.MonthlyPaymentList.length > 0"
                  >
                    <div class="pt-2 d-flex">
                      <h6 class="payment-col-width body-header-text">
                        Recurring Payment Details
                      </h6>
                      <p class="payment-col-width">eZapp</p>
                      <p class="payment-col-width">VCall</p>
                    </div>
                    <table class="table table-striped table-sm table-bordered">
                      <tbody *ngFor="let item of paymentGroup?.itemsMaster">
                        <tr *ngIf="item.tag === 'APPLICATION.PAYMENT.BANKNAME'">
                          <th class="payment-col-width">Bank Name</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.bankName ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ item?.vCall?.value || "N/A" }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item.tag === 'APPLICATION.PAYMENT.BANKROUTINGCODE'
                          "
                        >
                          <th class="payment-col-width">Routing</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.bankRoutingCode ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ item?.vCall?.value || "N/A" }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item.tag === 'APPLICATION.PAYMENT.BDACCOUNTNUMBER'
                          "
                        >
                          <th class="payment-col-width">Account</th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.bdAccountNumber ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ (item?.vCall?.value | maskAccountNumber) || "N/A" }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            item.tag ===
                            'APPLICATION.PAYMENT.REQUESTEDEFFECTIVEDATE'
                          "
                        >
                          <th class="payment-col-width">
                            Requested Effective Date
                          </th>
                          <td class="payment-col-width bg-white">
                            {{
                              item?.ezApp?.value ||
                                application.payment.requestedEffectiveDate ||
                                "N/A"
                            }}
                          </td>
                          <td class="payment-col-width bg-white">
                            {{ item?.vCall?.value || "N/A" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="paymentGroup?.itemsMaster.length === 0">
                  <div class="mt-2 mb-2 text-center call-info">
                    No Data Available
                  </div>
                </ng-container>
              </div>
              <ng-template #noPaymentData>
                <ng-container
                  [ngTemplateOutlet]="noDataTemplate"
                  [ngTemplateOutletContext]="{ messageVar: 'Payment' }"
                >
                </ng-container>
              </ng-template>
              <!-- END : Payment section ends -->

              <!-- START : Rx Profile Global-->
              <div
                *ngIf="
                  rxProfileGlobalGroup?.configuration?.displayLabel ===
                    'Rx Profile';
                  else noRxProfileData
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ rxProfileGlobalGroup?.configuration?.displayLabel }}
                </h5>
                <ng-container
                  *ngIf="rxProfileGlobalGroup?.itemsMaster.length > 0"
                >
                  <div *ngFor="let item of rxProfileGlobalGroup?.itemsMaster">
                    <div class="applicable pt-2">
                      <div [innerHTML]="item?.vCall.prompt"></div>
                      <div class="font-weight-bold pt-3 pb-2">
                        <!-- need to write some better logic -->
                        <ng-container *ngIf="item?.vCall.value === 'true'">
                          RX authorization was obtained for
                          {{ selectedApplicant.fullName }}
                        </ng-container>
                        <ng-container *ngIf="item?.vCall.value === 'false'">
                          {{ selectedApplicant.fullName }}'s Rx Authorization
                          was not obtained due to one of these reasons below:
                          <ul class="pt-3 f-w-400">
                            <li>
                              Was not available or online to obtain the
                              authorization
                            </li>
                            <li>Was an underage dependent</li>
                            <li>Was not part of any healthcare profession</li>
                          </ul>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="rxProfileGlobalGroup?.itemsMaster.length === 0"
                >
                  <p class="call-info m-3">No Data Available</p>
                </ng-container>
              </div>
              <ng-template #noRxProfileData>
                <ng-container
                  [ngTemplateOutlet]="noDataTemplate"
                  [ngTemplateOutletContext]="{ messageVar: 'Rx Profile' }"
                >
                </ng-container>
              </ng-template>
              <!-- END : Rx Profile Global-->
              <!-- START : Rx Profile -->
              <!-- <ng-container
                *ngIf="
                  rxProfileGroup?.itemsMaster.length > 0 &&
                  rxProfileGroup?.items.length > 0
                "
              >
                <h5 class="bg-secondary text-white text-center p-2">
                  {{ rxProfileGroup.configuration.displayLabel }}
                </h5>
                <div *ngFor="let item of rxProfileGroup?.items">
                  <div class="applicable pt-2">
                    <div [innerHTML]="item?.prompt"></div>
                    <div class="font-weight-bold pt-3 pb-2"> -->
              <!-- need to write some better logic -->
              <!-- <ng-container
                        *ngIf="item?.value === 'Applicant was available'"
                      >
                        {{ selectedApplicant.fullName }} was available online
                      </ng-container>
                      <ng-container
                        *ngIf="item?.value === 'Applicant not available'"
                      >
                        {{ selectedApplicant.fullName }}'s Rx Authorization was
                        not obtained due to one of these reasons below:
                        <ul class="pt-3 f-w-400">
                          <li>
                            Was not available or online to obtain the
                            authorization
                          </li>
                          <li>Was an underage dependent</li>
                          <li>Was not part of any healthcare profession</li>
                        </ul>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          item?.value !== 'Applicant not available' &&
                          item?.value !== 'Applicant was available'
                        "
                      >
                        {{ item?.value || "N/A" }}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container> -->
              <!-- END : Rx Profile -->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- START : NO Data templates starts -->
<ng-template let-message="messageVar" #noDataTemplate>
  <h5 class="bg-secondary text-white text-center p-2">
    {{ message }}
  </h5>
  <div class="call-info m-3">No Data Available</div>
</ng-template>
<!-- END : NO Data templates ends -->

<!-- Employments details template start -->
<ng-template let-employeeDetails #employeeDetailsTemplate>
  <p class="mb-1">{{ employeeDetails?.header }}</p>
  <table class="table table-striped table-sm table-bordered">
    <tbody *ngFor="let item of employeeDetails?.data">
      <tr *ngIf="item.clientNo && item.clientNo === selectedApplicant.clientNo">
        <th class="payment-col-width">
          {{ item.displayLabel }}
        </th>
        <td class="payment-col-width bg-white">
          {{ item?.ezAppValue || "N/A" }}
        </td>
        <td class="payment-col-width bg-white">
          <span
            [ngClass]="{
              'emp-mismatch-bg px-2': item?.ezAppValue !== item?.vCallValue
            }"
          >
            {{ item?.vCallValue || "N/A" }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
<!-- Employments details template ends -->

<!-- Keeping it as commented for future use case -->
<!-- create order popup starts from here -->
<!-- <app-create-order
  [openCreateOrderModal$]="openCreateOrderModal"
></app-create-order> -->
<!-- create order ends here -->